<template>
     <div class="container-fluid">
         <SuccessAlert ref="MySuccessAlert" :title="AlertTitle" :message="AlertMessage" :back="true"/>
          <div class="row row-custom">
            <div class="col-md-8">
              <div class="card">
                <div class="card-header card-header-primary">
                  <h4 class="card-title">اعدادات</h4>
                 </div>
                <div class="card-body">
                    <div class="row mb-3">
 
                       <div class="col-md-12">
                          <WYSISYG id_c="terms_and_conditions_ar_c" ref="terms_and_conditions_ar"  id="terms_and_conditions_ar" :value="form.terms_and_conditions.ar" @content="HandleNameInput('terms_and_conditions','ar',$event)" title="الشروط والاحكام بالعربي"/>
                       </div>
                       <div class="col-md-12 mt-5">
                          <WYSISYG id_c="terms_and_conditions_en_c" ref="terms_and_conditions_en"  id="terms_and_conditions_en" :value="form.terms_and_conditions.en" @content="HandleNameInput('terms_and_conditions','en',$event)" title="الشروط والاحكام بالانجليزي"/>
                       </div>

                       <div class="col-md-12 mt-5">
                          <WYSISYG id_c="opening_hours_ar_c" ref="opening_hours_ar"  id="opening_hours_ar" :value="form.opening_hours.ar" @content="HandleNameInput('opening_hours','ar',$event)" title="اوقات العمل بالعربي"/>
                       </div>
                       <div class="col-md-12 mt-5">
                          <WYSISYG id_c="opening_hours_en_c" ref="opening_hours_en" id="opening_hours_en" :value="form.opening_hours.en" @content="HandleNameInput('opening_hours','en',$event)" title="اوقات العمل بالانجليزي"/>
                       </div>

                       <div class="col-md-12 mt-5">
                          <WYSISYG id_c="contact_details_ar_c" ref="contact_details_ar"  id="contact_details_ar" :value="form.contact_details.ar" @content="HandleNameInput('contact_details','ar',$event)" title="معلومات التواصل بالعربي"/>
                       </div>
                       <div class="col-md-12 mt-5">
                          <WYSISYG id_c="contact_details_en_c" ref="contact_details_en" id="contact_details_en" :value="form.contact_details.en" @content="HandleNameInput('contact_details','en',$event)" title="معلومات التواصل بالانجليزي"/>
                       </div>

                       
                         <Input id="location" title="الموقع على الخريطه" @Input="HandleInput('location',$event)" Mykey="location"   :MyValue="form.location"/>


                    </div>

                    <button type="button" class="btn btn-primary pull-right"  @click="onUpdate()">تعديل </button>
                    <div class="clearfix"></div>



                </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import Input from '../../../../components/Form/TextInput'
 import {mapState,mapMutations} from 'vuex';
import store from '../../../../store/index'
import SuccessAlert from '../../../../components/SuccessAlert'
 import WYSISYG from '../../../../components/WYSISYG'
 export default {
  computed:{
 
 
   },
   data(){
       return{
           form:{
               id:'',
               terms_and_conditions:{
                   ar:'',
                   en:''
               },
               opening_hours:{
                   ar:'',
                   en:''
               },
               contact_details:{
                   ar:'',
                   en:''
               },
               location:'',
           },
           AlertTitle:'',
           AlertMessage:'',
 
       }
   },
   methods:{
 
       setData(vm,response){
            if(response.messageCode == '01'){
                let data=response.details;
                                
                vm.form.id=data.id;
                vm.form.terms_and_conditions.ar=data.terms_and_conditions.ar;
                vm.form.terms_and_conditions.en=data.terms_and_conditions.en;
                vm.form.opening_hours.ar=data.opening_hours.ar;
                vm.form.opening_hours.en=data.opening_hours.en;
                vm.form.contact_details.ar=data.contact_details.ar;
                vm.form.contact_details.en=data.contact_details.en;

                vm.$refs.terms_and_conditions_ar.value1=data.terms_and_conditions.ar;
                vm.$refs.terms_and_conditions_en.value1=data.terms_and_conditions.en;
                vm.$refs.opening_hours_ar.value1=data.opening_hours.ar;
                vm.$refs.opening_hours_en.value1=data.opening_hours.en;
                vm.$refs.contact_details_ar.value1=data.contact_details.ar;
                vm.$refs.contact_details_en.value1=data.contact_details.en;


                vm.form.location=data.location;
            }            
        },
       
 
       onUpdate(){
     //    this.isLoading=true;
          store.commit('admin/PleaseStartLoading');        
          let formData=new FormData(); 
          for (let key in this.form) {
            if(key == 'terms_and_conditions'){
              formData.append('terms_and_conditions[ar]', this.form[key].ar);
              formData.append('terms_and_conditions[en]', this.form[key].en);
            }else if(key == 'opening_hours'){
              formData.append('opening_hours[ar]', this.form[key].ar);
              formData.append('opening_hours[en]', this.form[key].en);
            } else if(key == 'contact_details'){
              formData.append('contact_details[ar]', this.form[key].ar);
              formData.append('contact_details[en]', this.form[key].en);
            }else{  
            formData.append(key, this.form[key]);
            }
          }
          formData.append('_method', "patch");
          store.commit('admin/PleaseStartLoading');
          store.dispatch('admin/updateSetting',formData).then((response)=>{
             store.commit('admin/PleaseStopLoading');
            this.AlertTitle="معلومات",
            this.AlertMessage="تم حفظ التغيرات بنجاح";
            this.$refs.MySuccessAlert.showModel();
          }).catch((error)=>{
          store.commit('admin/PleaseStopLoading');
          });
       },

 
  
     HandleInput(key,value){
         this.form[key]=value;
     },
     HandleNameInput(parent,key,value){
         this.form[parent][key]=value;
     },
 
   },
   components:{
         Input,
         SuccessAlert,
         WYSISYG,
       },
   
     beforeRouteEnter(to,from,next){  
        store.dispatch('admin/fetchSetting', {}).then((response) => {
            next(vm => {
                vm.setData(vm,response.data);

            });
        });
    },
      beforeDestroy(){
         this.cleanErrors();
     }
}
</script>

<style lang="scss">
/*
 *
 */
.row-custom{
    justify-content: center;
}


















</style>